import { fetchCompanyInfo, getCompanyId, messageList } from '@/api/api';

function initialState() {
  return {
    auth: null, //预检测得到的信息
    zq:'',
    abilities: [],
    resultStyle: ''
  };
}

export const state = {
  ...initialState()
};

export const mutations = {
  setAuth(state, payload) {
    state.auth = payload;
  },
  setZq(state,zq){
    state.zq = zq;
  },
  setResultStyle(state, payload) {
    state.resultStyle = payload;
  },
  setAbilities(state, payload) {
    state.abilities = payload;
  },
  //重置这个store初始状态
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s)
      .forEach(key => {
        state[key] = s[key];
      });
  },
};

export const actions = {};
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
