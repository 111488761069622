import Vue from "vue";
import Router from "vue-router";
import routerConfig from "@/config/routes";
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
Vue.use(Router);

export default new Router({
  base:'/',
  mode: 'history',
  routes: routerConfig
});
