<template>
  <router-view v-if="showReloadIndex" />
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      showReloadIndex: true,
    };
  },
  methods: {
    reload() {
      this.showReloadIndex = false;
      this.$nextTick(function () {
        this.showReloadIndex = true;
      });
    },
  },
};
</script>
<style>
.qiangzhi,.chart-item{
    page-break-before: always;
}
.qiangzhi{
  display: block!important;
}
</style>